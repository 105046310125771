import { defineComponent, onMounted } from "vue";
export default defineComponent({
  name: "Equip",
  setup: function setup() {
    var url = 'https://bcepd-dev.atuniversity.cn/z_res/paintbook/seepic.png';
    var srcList = ['https://bcepd-dev.atuniversity.cn/z_res/paintbook/3c.jpg'];
    onMounted(function () {
      window.scrollTo(0, 0);
    });
    return {
      url: url,
      srcList: srcList
    };
  }
});